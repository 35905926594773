// extracted by mini-css-extract-plugin
export var age = "layout-module--age--ve+1O";
export var blu = "layout-module--blu--pKUkW";
export var button = "layout-module--button--DAaNm";
export var container = "layout-module--container--AzcEY";
export var content = "layout-module--content--NPZN0";
export var enrollmentWrap = "layout-module--enrollment-wrap--RD0ta";
export var grid2 = "layout-module--grid-2--6B8Ht";
export var sliderImg = "layout-module--slider-img--vMf2M";
export var sticky = "layout-module--sticky--eUbeY";
export var subMenu = "layout-module--sub-menu--z5HDp";